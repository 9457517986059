export function formatCloudinaryUrl(imageUrl: string | undefined | null, params = ['f_auto', 'fl_lossy', 'q_auto:good']) {
  if (!imageUrl || imageUrl.startsWith('blob:')) return imageUrl;

  const dpr = typeof window !== 'undefined' ? window.devicePixelRatio.toFixed(1) : '1.0';
  if (dpr) params.push(`dpr_${dpr}`);

  const regex = /https?:\/\/res.cloudinary.com\/crowdselling\/image\/upload\/(.*)/;
  if (regex.test(imageUrl)) {
    const publicId = imageUrl?.match(regex)?.[1];
    return `https://res.cloudinary.com/crowdselling/image/upload/${params.join(',')}/${publicId}`;
  }

  return `https://res.cloudinary.com/crowdselling/image/fetch/${params.join(',')}/${imageUrl}`;
}

export function toCloudinaryUrl(imgUrl: string, width: number) {
  return formatCloudinaryUrl(imgUrl, [`w_${width}`, 'c_fill_pad', 'g_auto', 'ar_1:1', 'f_auto', 'fl_lossy', 'q_auto:good'])
    || imgUrl;
}
