import * as React from 'react';
import { AlgoliaWebshop } from '../../../types/algolia.model';
import * as styles from './webshop-item.module.scss';
import { toCloudinaryUrl } from '../../../utils/cloudinary-utils';

interface WebshopItemProps {
  webshop: AlgoliaWebshop
}

function WebshopItem({ webshop }: WebshopItemProps) {
  return (
    <div className={styles.container}>
      <div className={styles.border}>
        <img
          alt={webshop.association.name}
          className={styles.logo}
          src={
            toCloudinaryUrl(webshop.association.logoUrl, 120)
            // eslint-disable-next-line max-len
            || 'https://res.cloudinary.com/crowdselling/image/upload/w_120,c_fill_pad,g_auto,ar_1:1,f_auto,fl_lossy,q_auto:good/partners/662f97f165be2f3bb30af973/o3htjnuqdjjvgjrgzbbh.png'
          }
        />
        <div className={styles.content}>
          <div className={styles.data}>
            <div className={styles.name}>{webshop.association.name}</div>
          </div>
        </div>
      </div>
    </div>

  );
}

export default WebshopItem;
