import * as React from 'react';
import {
  graphql,
  navigate,
  Script,
  type PageProps,
} from 'gatsby';
import { Trans, useI18next } from 'gatsby-plugin-react-i18next';
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { getOppositeLanguage } from '../../utils/i18n-utils';
import { TROOPER_PATHS } from '../../../constants';
import Layout from '../../components/layout';
import { TrooperQueryParam } from '../../utils/query-params-const';
import { AlgoliaWebshop } from '../../types/algolia.model';
import SpinnerDots from '../../components/spinner-dots/spinner-dots';
import * as styles from './julesdestrooper.module.scss';
import AssociationNoResults from '../../components/associations/association-no-results/association-no-results';
import WebshopItem from '../../components/julesdestrooper/webshop-item/webshop-item';
import { Language } from '../../types/enums/language.enum';
import { JdsSearchFr, JdsSearchNl } from '../../devlink';

const fetchWebshops = async (
  searchTerm: string,
  city: string | null,
  page: number,
  pageSize: number,
  articlePackageId: string,
) => {
  const urlIndexPart = `${process.env.GATSBY_ALGOLIA_QUERY_URL!
    .replace('{indexName}', process.env.GATSBY_ALGOLIA_WEBSHOPS_INDEX!)}`;
  const url = `${process.env.GATSBY_ALGOLIA_BASE_URL}${urlIndexPart}`;

  const cityFilter = city ? `city.name:'${city}'` : null;
  const articlePackageFilter = `articlePackage._id:'${articlePackageId}'`;
  const filters = cityFilter ? `${articlePackageFilter} AND ${cityFilter}` : articlePackageFilter;

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Algolia-API-Key': process.env.GATSBY_ALGOLIA_API_KEY!,
      'X-Algolia-Application-Id': process.env.GATSBY_ALGOLIA_APPLICATION_ID!,
    },
    body: JSON.stringify({
      query: searchTerm,
      filters,
      hitsPerPage: pageSize,
      page: page - 1,
      responseFields: ['hits'],
    }, (_, v) => v ?? undefined),
  })
    .then(async (response) => response.json())
    .then((response) => {
      if (response?.hits) {
        return response.hits;
      }
      return [];
    });
};

function JulesDesTrooperPage({ location }: PageProps) {
  const params = new URLSearchParams(location.search);
  const searchTerm = params.get(TrooperQueryParam.AssociationSearchTerm);
  const city = params.get(TrooperQueryParam.City);
  const pageSize = 24;

  const [webshops, setWebshops] = useState([] as AlgoliaWebshop[]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [canLoadMore, setCanLoadMore] = useState(true);
  const { language } = useI18next();

  const oppositeLanguage = getOppositeLanguage(language);
  const otherLanguagePath = `/${oppositeLanguage}/${TROOPER_PATHS[oppositeLanguage].julesDestrooper}`;

  const articlePackageId = language === 'nl' ? '662f9959bc853b8f156ca715' : '66a0db0fed67a1677fdfbf54';

  useEffect(() => {
    fetchWebshops(searchTerm || '', city, page, pageSize, articlePackageId)
      .then((newWebshops) => {
        setWebshops(newWebshops);
        setIsLoading(false);
        setCanLoadMore(newWebshops.length === pageSize);
      });
  }, []);

  const handleLoadMore = () => {
    setIsLoading(true);
    const newPage = page + 1;
    setPage(newPage);
    fetchWebshops(searchTerm!, city, newPage, pageSize, articlePackageId).then((newWebshops) => {
      setWebshops((arr) => arr.concat(newWebshops));
      setIsLoading(false);
      setCanLoadMore(newWebshops.length === pageSize);
    });
  };

  const onWebshopClick = (link: string) => {
    navigate(`/${link}`);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>, link: string) => {
    if (event.key === 'Enter') {
      onWebshopClick(link);
    }
  };

  const searchBanner = language === Language.NL ? <JdsSearchNl /> : <JdsSearchFr />;

  return (
    <Layout otherLanguagePath={otherLanguagePath}>
      <Script id="trooper-web-components" src={process.env.GATSBY_WEB_COMPONENTS_URL} />
      {searchBanner}
      <div className={clsx(styles.container, styles.main)}>
        <div className={styles.webshopsContainer}>
          {!isLoading && webshops.length === 0 ? <AssociationNoResults /> : null}
          {webshops.map((webshop: AlgoliaWebshop) => (
            <div
              className={styles.webshop}
              key={webshop.objectID}
              onClick={() => { onWebshopClick(webshop.link); }}
              onKeyDown={(event) => handleKeyDown(event, webshop.link)}
              role="button"
              tabIndex={0}
              aria-label={webshop.association.name}
            >
              <WebshopItem
                webshop={webshop}
              />
            </div>
          ))}
        </div>
        <div className={styles.loadMore}>
          {
            !isLoading && canLoadMore
            && (
              <button type="button" className="btn btn--orange" onClick={handleLoadMore}>
                <Trans>Associations_LoadMore</Trans>
              </button>
            )
          }
          <SpinnerDots isLoading={isLoading} showBackdrop={false} />
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
    query JulesDesTrooperPage($language: String!) {
        locales: allLocale(filter: {ns: {in: [
          "Associations",
          "Association_NoResults"
        ]}, language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

export default JulesDesTrooperPage;
