// @ts-nocheck
"use client";
import React from "react";
import * as _Builtin from "./_Builtin";

export function SearchBar({ as: _Component = _Builtin.Section }) {
  return (
    <_Component
      className="red-section"
      grid={{
        type: "section",
      }}
      tag="section"
    >
      <_Builtin.BlockContainer
        className="_wf-container"
        grid={{
          type: "container",
        }}
        tag="div"
      >
        <_Builtin.Heading className="white-heading home-hero" tag="h1">
          {
            "SHOP JIJ ONLINE? MAAK EEN EXTRA KLIK VIA TROOPER EN JE STEUNT JE VERENIGING OF GOED DOEL GRATIS."
          }
          <br />
        </_Builtin.Heading>
        <_Builtin.HtmlEmbed
          className="html-embed search-bar"
          value="%3Cscript%20defer%20src%3D%22https%3A%2F%2Ftrooperv2.blob.core.windows.net%2Fweb-components%2Fprod%2Fwebsite%2Fmain.js%22%20type%3D%22text%2Fjavascript%22%3E%3C%2Fscript%3E%0A%3Cdiv%20style%3D%22position%3A%20relative%3B%22%3E%0A%09%3Ctrooper-associations-search%20language%3D%22nl%22%3E%3C%2Ftrooper-associations-search%3E%0A%3C%2Fdiv%3E"
        />
      </_Builtin.BlockContainer>
    </_Component>
  );
}
