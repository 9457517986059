import * as React from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';
import * as styles from './association-no-results.module.scss';

function AssociationNoResults() {
  const { t } = useI18next();

  return (
    <div className={styles.container}>
      <div>
        <h1 className={styles.title}>{t('Association_NoResults_Title')}</h1>
        <div
          className={styles.description}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={
                   { __html: t('Association_NoResults_Description', { interpolation: { escapeValue: false } }) }
               }
        />
      </div>
    </div>
  );
}

export default AssociationNoResults;
