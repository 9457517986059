import * as React from 'react';
import { clsx } from 'clsx';
import * as styles from './spinner-dots.module.scss';

interface SpinnerDotsProps {
  isLoading: boolean;
  showBackdrop: boolean;
}

function SpinnerDots({ isLoading, showBackdrop }:SpinnerDotsProps) {
  const containerClassNames = clsx(
    styles.spinnerContainer,
    showBackdrop && styles.center,
  );

  return (
    <>
      { isLoading
                && (
                <div>
                  <div className={containerClassNames}>
                    <div className={styles.spinner}>
                      <div className={styles.bounce1} />
                      <div className={styles.bounce2} />
                      <div />
                    </div>
                  </div>
                  <div className={clsx(showBackdrop && styles.backdrop)} />
                </div>
                )}
    </>
  );
}

export default SpinnerDots;
