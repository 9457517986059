// @ts-nocheck
"use client";
import React from "react";
import * as _Builtin from "./_Builtin";

export function JdsSearchNl({ as: _Component = _Builtin.Section }) {
  return (
    <_Component
      className="color-section jds-dark-blue"
      grid={{
        type: "section",
      }}
      tag="section"
    >
      <_Builtin.BlockContainer
        className="_wf-container"
        grid={{
          type: "container",
        }}
        tag="div"
      >
        <_Builtin.Row
          className="columns-13"
          tag="div"
          columns={{
            main: "2|8|2",
            medium: "",
            small: "",
            tiny: "",
          }}
        >
          <_Builtin.Column className="column-29" tag="div">
            <_Builtin.Image
              loading="lazy"
              width="auto"
              height="auto"
              alt=""
              src="https://cdn.prod.website-files.com/64b78c42e7672852e3e2976f/66ab3e7d2a0e82ad365b7b17_1.png"
            />
          </_Builtin.Column>
          <_Builtin.Column className="column-27" tag="div">
            <_Builtin.Heading className="jds-dark-blue-h1" tag="h1">
              {"De jules destrooper-actie"}
              <br />
            </_Builtin.Heading>
            <_Builtin.Heading className="jds-dark-blue-h2" tag="h2">
              {"Zoek hieronder je vereniging & ontdek hun webshop"}
              <br />
            </_Builtin.Heading>
            <_Builtin.HtmlEmbed
              className="html-embed search-bar"
              value="%3Cscript%20defer%20src%3D%22https%3A%2F%2Ftrooperv2.blob.core.windows.net%2Fweb-components%2Fprod%2Fwebsite%2Fmain.js%22%20type%3D%22text%2Fjavascript%22%3E%3C%2Fscript%3E%0A%3Cdiv%20style%3D%22position%3A%20relative%3B%22%3E%0A%09%3Ctrooper-associations-search%20language%3D%22nl%22%20jules-destrooper%3D%22true%22%3E%3C%2Ftrooper-associations-search%3E%0A%3C%2Fdiv%3E"
            />
          </_Builtin.Column>
          <_Builtin.Column className="column-28" tag="div">
            <_Builtin.Image
              loading="lazy"
              width="auto"
              height="auto"
              alt=""
              src="https://cdn.prod.website-files.com/64b78c42e7672852e3e2976f/66ab3e7d2a0e82ad365b7b17_1.png"
            />
          </_Builtin.Column>
        </_Builtin.Row>
      </_Builtin.BlockContainer>
    </_Component>
  );
}
