// @ts-nocheck
"use client";
import React from "react";
import * as _Builtin from "./_Builtin";

export function SearchBarFr({ as: _Component = _Builtin.Block }) {
  return (
    <_Component className="red-section" tag="section">
      <_Builtin.BlockContainer
        className="_wf-container"
        grid={{
          type: "container",
        }}
        tag="div"
      >
        <_Builtin.Heading className="white-heading home-hero" tag="h1">
          <_Builtin.Strong>
            {
              "TU FAIS TON SHOPPING EN LIGNE? AVEC JUSTE UN CLIC DE PLUS SUR LA PAGE TROOPER, TU SOUTIENS TON ASSOCIATION OU ŒUVRE CARITATIVE GRATUITEMENT."
            }
          </_Builtin.Strong>
          <br />
        </_Builtin.Heading>
        <_Builtin.HtmlEmbed
          className="html-embed search-bar"
          value="%3Cscript%20defer%20src%3D%22https%3A%2F%2Ftrooperv2.blob.core.windows.net%2Fweb-components%2Fprod%2Fwebsite%2Fmain.js%22%20type%3D%22text%2Fjavascript%22%3E%3C%2Fscript%3E%0A%3Cdiv%20style%3D%22position%3A%20relative%3B%22%3E%0A%09%3Ctrooper-associations-search%20language%3D%22fr%22%3E%3C%2Ftrooper-associations-search%3E%0A%3C%2Fdiv%3E"
        />
      </_Builtin.BlockContainer>
    </_Component>
  );
}
