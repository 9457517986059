import { Language } from '../types/enums/language.enum';

export function replaceParamInTranslation(
  translatedText: string,
  paramName: string,
  paramValue: string,
) {
  return translatedText.replaceAll(`{{${paramName}}}`, paramValue);
}

export function replaceParamsInTranslation(
  translatedText: string,
  paramNames: string[],
  paramValues: string[],
) {
  let text = translatedText;
  if (paramNames.length !== paramValues.length) return text;
  paramNames.forEach((pn, index) => {
    text = replaceParamInTranslation(
      text,
      pn,
      paramValues[index],
    );
  });
  return text;
}

export function getOppositeLanguage(language: string) {
  return language === Language.FR ? Language.NL : Language.FR;
}
